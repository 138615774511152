import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/da';
import axios from 'axios';

dayjs.locale('da');

// 1. Define route components.
// These can be imported from other files
var apiUrl = 'https://www.addanmark.dk/wp-json/';
var apikey = '5d41402abc4b2a76b9719d911017c592';

var AllNewsTemplate = `
    <div class="all-news">

        <loading-spinner :pageLoaded="pageLoaded"></loading-spinner>
    
        <div class="news-list" v-if="countOldPosts < 3" v-show="postsLoaded">
            <h1>Nyheder</h1>
            <a href="/nyheder/" class="show-all">Vis alle</a>

            <ul class="news">

                <li v-for="news in AllNews" :key="news.id" v-if="!news.oldPost">
                    <div>
                        <a :href="formatUrl(news.slug, 'news')">
                            <h3 v-html="news.title.rendered"></h3>
                        </a>
                        <a :href="formatUrl(news.slug, 'news')"><small>{{news.date}}</small></a>
                    </div>
                </li>

            </ul>

        </div>

        <div class="campaign-list" v-if="activeCampaigns">
            <h1>Kampagner</h1>
            <a href="/kampagne/" class="show-all">Vis alle</a></span>

            <ul class="campaigns">

                <li v-for="campaign in AllCampaigns">
                    <div>
                        <a :href="formatUrl(campaign.slug, 'campaign')">
                            <h3 v-html="campaign.title.rendered"></h3>
                        </a>
                        <a :href="formatUrl(campaign.slug, 'campaign')"><small>{{campaign.date}}</small></a>
                    </div>
                </li>

            </ul>

        </div>

    </div>
`;

// Show all News
var AllNews = Vue.component('AllNews', {});

Vue.component('loading-spinner', {
  props: ['pageLoaded'],

  data: function() {
    return {};
  },
  template:
    "<div class='spinner' v-if='!pageLoaded'><div class='sk-fading-circle'><div class='sk-circle1 sk-circle'></div><div class='sk-circle2 sk-circle'></div><div class='sk-circle3 sk-circle'></div><div class='sk-circle4 sk-circle'></div><div class='sk-circle5 sk-circle'></div><div class='sk-circle6 sk-circle'></div><div class='sk-circle7 sk-circle'></div><div class='sk-circle8 sk-circle'></div><div class='sk-circle9 sk-circle'></div><div class='sk-circle10 sk-circle'></div><div class='sk-circle11 sk-circle'></div><div class='sk-circle12 sk-circle'></div></div></div>",
});

var RecentPosts = Vue.component('RecentPosts', {
  data: function() {
    return {
      AllNews: '',
      AllCampaigns: '',
      publishDate: '',
      countOldPosts: 0,
      activeCampaigns: false,
      paginationCreated: false,
      pageLoaded: false,
      postsLoaded: false,
    };
  },

  // Get all News
  created: function() {
    this.fetchNews();
    this.fetchCampaigns();
  },

  methods: {
    /**
     * Get all News
     */
    fetchNews: function() {
      var self = this;
      axios
        .get(apiUrl + 'wp/v2/news?categories=5&per_page=3&v=' + Math.floor(Date.now() / 1000), {
          headers: {
            apikey: apikey,
          },
        })
        .then(function(response) {
          //self.pageLoaded = true;
          self.setNews(response);
        })
        .catch(function(error) {
          console.log('Error', error);
        });
    },

    /**
     * Get all Campaigns
     */
    fetchCampaigns: function() {
      var self = this;
      axios
        .get(apiUrl + 'wp/v2/news?categories=3&per_page=3&v=' + Math.floor(Date.now() / 1000), {
          headers: {
            apikey: apikey,
          },
        })
        .then(function(response) {
          //self.pageLoaded = true;
          self.setCampaigns(response);
        })
        .catch(function(error) {
          console.log('Error', error);
        });
    },

    /**
     * Set News
     */
    setNews: function(posts) {
      this.AllNews = posts.data;
      this.formatDate(true);
    },

    /**
     * Set Campaign
     */
    setCampaigns: function(posts) {
      if (posts.data.length) {
        this.AllCampaigns = posts.data;
        this.formatDate(false);
        this.activeCampaigns = true;
      }
    },

    /**
     * format date
     */
    formatDate: function(news) {
      if (news) {
        for (var i = 0; i < this.AllNews.length; i++) {
          //console.log(dayjs().diff(this.AllNews[i].date, 'months'))
          if (dayjs().diff(this.AllNews[i].date, 'months') > 2) {
            this.AllNews[i].oldPost = true;
            this.countOldPosts++;
          } else {
            //this.AllNews[i].date = dayjs(this.AllNews[i].date).format("Do MMMM YYYY HH:mm")
            this.AllNews[i].date = dayjs(this.AllNews[i].date).format('D MMMM YYYY');
          }
        }
        this.postsLoaded = true;
      } else {
        for (var j = 0; j < this.AllCampaigns.length; j++) {
          //this.AllCampaigns[i].date = dayjs(this.AllCampaigns[i].date).format("Do MMMM YYYY HH:mm")
          this.AllCampaigns[j].date = dayjs(this.AllCampaigns[j].date).format('D MMMM YYYY');
        }
      }
    },

    /**
     * returns absolute url
     */
    formatUrl: function(slug, type) {
      //console.log(slug, type);
      if (type == 'news') {
        return '/nyheder/' + slug;
      } else {
        return '/kampagne/' + slug;
      }
    },
  },
  template: AllNewsTemplate,
});

export default RecentPosts;
